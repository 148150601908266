/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import CookieConsent from "react-cookie-consent";
import { useLocation } from "@reach/router" // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import PropTypes from "prop-types"
import Header from "./Header"
import Footer from "./Footer"
import { Link, useStaticQuery, graphql } from "gatsby"
// import { useStaticQuery, graphql } from "gatsby"


const Layout = ({ children }) => {
  const location = useLocation();
  const data = useStaticQuery(graphql`
        query Cookie {
            cookieJson {
              cookie_text
              cookie_button_accept
              cookie_button_reject
            }
        }
    `)
    const { cookieJson } = data;
  
  return (
    <>
      <Header />
      <main>
        {children}
      </main>
      <CookieConsent
        location="bottom"
        buttonText={cookieJson.cookie_button_accept}
        declineButtonText={cookieJson.cookie_button_reject}
        cookieName="gatsby-gdpr-google-analytics"
        onAccept={() => initializeAndTrack(location)}
        // ALE: questo sono i colori del banner per i cookies
        // style={{ background: "#2B373B" }}
        // buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        {cookieJson.cookie_text}{" "}<Link to="/privacy">Privacy Policy</Link>
      </CookieConsent>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
