// Gatsby supports TypeScript natively!
import React, {useState, useEffect,useRef} from "react"
// import {  Link } from "gatsby"
// import Layout from "../components/layout"
// import SEO from "../components/seo"
import { AnchorLink } from "gatsby-plugin-anchor-links";
// import menuData from "../../content/menu.json";
import { useStaticQuery, graphql } from "gatsby"
import {logo} from '@src/config'
import useScroll from '@src/hooks/useScroll';
import Hamburger from '@components/Basic/Hamburger';


const Header = () => {
    const data = useStaticQuery(graphql`
        query menu {
            allMenuJson {
            nodes {
                id
                label
                url
            }
            }
        }
   `)  
    const menuData = data.allMenuJson.nodes;
    const [openNavMobile, setOpenNavMobile] = useState(false)
    const navRef = React.useRef<HTMLDivElement>(null);
    const onScroll = useScroll()
   
    let resizeTimer;
    const onResize = () => {
        navRef.current.classList.add("resize-transition-stop");
        
        clearTimeout(resizeTimer);
        
        resizeTimer = setTimeout(() => {
            navRef.current.classList.remove("resize-transition-stop");
        }, 400);

    }

    useEffect(() => {
        window.addEventListener('resize', onResize)
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])


    return (
        <header className={onScroll.headerClass}>
            <div className="container">
                <div className="inner-header flex">
                    <div className="logo">
                        <AnchorLink to="/#hero">
                            <img src={logo} alt="inkode logo" title="Torna alla homepage" />
                        </AnchorLink>
                    </div>
                    <nav className={`${openNavMobile ? 'open' : ''}`} ref={navRef}>
                        {menuData.length > 0 && 
                            <ul> 
                            {menuData.map(item => (
                                <li key={item.id} className="menu-item">
                                    <AnchorLink 
                                        to={`/#${item.url}`} 
                                        title={item.label}>
                                        {item.label}
                                    </AnchorLink>
                                </li>
                            ))}
                            </ul>
                        }
                    </nav>
                    <Hamburger openNavMobile={openNavMobile} onSetOpenNavMobile={(state) => setOpenNavMobile(state)} />
                </div>
            </div>
        </header>
    )
}

export default Header