import React from 'react'
import {Link} from 'gatsby'
import {linkedinIcon, facebookIcon, facebookUrl, linkedinUrl, logoFooter} from '@src/config'

const Footer = () => {
    return (
        <footer className="section">
            <div className="container">
                <div className="logo-footer">
                    <img src={logoFooter} alt="inkode logo" />
                </div>
                <div className="text-footer">
                    <p>
                        Inkode soc. coop. Via Gobetti 101, Bologna - info@inkode.it © Copyright 2021 P.IVA 03662091200 | All rights reserved - <Link to="/privacy">Privacy Policy</Link>
                    </p>
                    <span>
                        <a href={facebookUrl} target="_blank">
                            <img src={facebookIcon} />    
                        </a>
                        <a href={linkedinUrl} target="_blank">
                            <img src={linkedinIcon} />
                        </a>
                    </span>    
                </div>
                
            </div>
        </footer>
    )
}

export default Footer