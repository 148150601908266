import React, {useState, useEffect} from 'react';

const useScroll = () => {
    const [headerClass, setHeaderClass] = useState('')
    
    let isScrolling;
    let lastScrollTop = 0;
    
    const onScroll = (e) => {
        // ie fix
        if(!e.target.scrollingElement){
            headerClass !== "with-background" && setHeaderClass('with-background')
            return
        }
        const scrollTop = e.target.scrollingElement.scrollTop
        
        if (scrollTop > 80 && scrollTop > lastScrollTop) {
            // downscroll 
            setHeaderClass('scroll-down')
        } else if(scrollTop < 30) {
            setHeaderClass('')
        } else {
            // upscroll 
            setHeaderClass('with-background')
        }

        window.clearTimeout( isScrolling );
        
        isScrolling = setTimeout(function() {
            lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
        }, 100);
    }

    useEffect(() => {
        window.addEventListener('scroll', onScroll)
        return (() => {
            window.removeEventListener('scroll', onScroll)
        })
    }, [])

    return {headerClass}
}

export default useScroll