import React from 'react'

const Hamburger = (props) => (
    <div id="mobile-icon" 
        className={`${props.openNavMobile ? 'open' : ''} is-visible-tablet`} 
        onClick={() => props.onSetOpenNavMobile(!props.openNavMobile)}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>
)

export default Hamburger